.selectFromInput {
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: #ffffff;
  font-size: 14px;
}
.selectFromInput input {
  background-color: inherit;
  outline: none;
  padding: 0 4px;
  width: 100%;
  cursor: pointer;
  border: 0;
  color: inherit;
}

.selectFromInput .listContainer {
  position: absolute;
  width: 100%;
  z-index: 99;
  max-height: 360px;
  overflow-y: auto;
}

.header-class {
  border: 1px solid #ced4da;
  height: 40px;
  border-radius: 4px;
  color: #495057;
}
.content-class {
  background-color: #dee4e2;
}

.selectFromInput .block {
  display: block;
}
.selectFromInput .hidden {
  display: none;
}

.selectFromInput .header {
  padding: 0 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.selectFromInput .listContainer > li {
  list-style: none;
  width: 100%;
  cursor: pointer;
  padding: 4px 8px;
  margin: 4px 0;
  color: #495057;
}

.selectFromInput .listContainer > li:hover {
  background-color: #dae0de;
}
