.make-wrapper .header {
  font-size: 14px;
  background-color: #309cda;
  color: white;
  padding: 6px;
  /* border-radius: 8px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: 14px;
}

.make-wrapper h6 {
  height: 36px;
}
.make-wrapper .content {
  background: #a9a9a912;
  padding: 10px;
  border-radius: 8px;
}
