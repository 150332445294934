body{
    /* background-image: url(../assets/images/bg/background_login.png); */
    background-color: white;
    color: gray;
}

.login-main-container{
  position: absolute;
  width: 250px;
  height: 250px;
  top:0px;
  left:0px;
}



.login-container {
    display: grid;
    height: 100vh;
    grid-template-rows: 50px 3px 1fr auto auto auto;
  }
  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-top: 15px;
    
  }
  .login-search-container{
    display: flex;
    padding: 5px;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .header-text-style{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
  }
  .search-item{
    height: 35px;
  }
  .login-seperator {
    background-color: #E1E1E1;
  }
  .login-middle {
    display: grid;
    height: 100%;
    grid-template-columns: 2fr 1fr;
    justify-content: center;
    align-items: center;
    
  }
  .login-background-image{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .login-form-style{
    margin: 10px;
    

  }

  .forgot-form-style{
    margin: 10px;
    background-color: #ffffff88!important;
    padding: 10px;
    border-radius: 10px;

  }

  .font-size-32px{
    font-size: 32px;
  }
  .font-size-24px{
    font-size: 24px;
  }
  .font-style-bold{
    font-style: bold;
  }
  .planning-text-style{
    display: flex;
    justify-content: flex-end;
  }

  .form-style-middle{
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #ffffff88!important;
    border-radius: 10px;
  }

  #cb3 {
    accent-color: #e74c3c;
  }

  .text-center{
    text-align: center;
  }

  .login-bottom-upper {

    width: 100%;
    align-items: center;
   
   
  }
  .login-bottom-last {
    display: flex;
    font-size: 12px;
    padding: 5px;
    justify-content: center;

  }

  .copyright-style{
    display: flex;
    justify-content:center;
    align-items: bottom;
    font-size:10px;
  }
