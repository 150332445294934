*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide-border:before,.hide-border:after{
  display: none;
}
.dd-item::marker{
  display: none !important;
  font-size: 0px;
} 
.btn-outline-secondary:hover{
  color: #ffffff !important;
}

.rsm-svg{
  height: 100%;
  width: 100%;
}

.modal{
z-index: 11112 !important;
}



.image-gallery-play-button{
  display: none;
}

.font-size-10px{
  font-size: 10px;
}

.font-size-8px{
  font-size: 8px;
}

.image-gallery{
  width: 50%;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 70px !important;
  width: 60px !important;
}

.card .header .header-dropdown li .dropdown-menu, .header-dropdown .dropdown-menu{
    background: #17191c; 
    border-radius: .55rem;
    overflow: hidden;
    transform: none !important;
    left: auto !important;
    right: 10px !important;
    padding: 10px;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.5);
    border: 0;
    top:10px !important;
    color: #999 !important;
}
.card .header .header-dropdown li a{
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

.card .header .header-dropdown li a:hover{
  color: #fff !important;
}
.accordion .card .card-header{
  background-color: transparent !important;
}
.accordion .card .card-header .btn-link{
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container{
  display: none !important;
}

.hide-toggle:after{
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: "" !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control{
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.hide-toggle .btn-outline-secondary{
  height: calc(1.5em + 0.75rem + 5px) !important;
}
.jvectormap-container{
  height: 300px;
}
.jvectormap-zoomin{
  position: absolute;
    top: 0px;
    background: #000;
    color: #fff;
    padding: 2px 7px;
}
.jvectormap-zoomout{
  position: absolute;
    top: 27px;
    background: #000;
    color: #fff;
    padding: 2px 7px;
}
.list-group-item + .list-group-item{
  border-top-width:1px !important;
}
.hclass {
  left: -40px !important;
}
.container-summary{
  margin-top: 10px;
}

.container-card{
  display: flex;
  flex-direction: row;
}
.card-item {
  width: 200px;
  margin: 10px;

}
.fullWidthAndHeight{
  width: 100%;  
  display: flex;
  flex-direction: row;
}

.background-gray-color{
  background: #282828;
  margin: 1px;  

}

.centerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.contercontainer Loader{
  width: 100px;  
  height: 100px;
}

.device-container{
  display: flex;
  flex-direction: column;
}

.item-device{
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
  padding: 10px;
}
.grid-item {
  
}

.device-img-size{
  width: 100px;
  height: 100px;
}

.indicator-img-size{
  width: 50px;
  height: 50px;
}

.image-item{
  display: flex;
  flex-direction: column;
 
}

.grid-item-sub {
  background-color: red;
  margin: 5px;
}

.body-grid{
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: space-between;
  row-gap: 5px;
  column-gap: 5px;
}

.body-grid-item-image {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 10px;

 }
 .body-grid-item-text {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-left: 10px;
 }
 .indicator-img{
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 5;
  grid-column-end: 6;
  align-self: top;
  margin-right: 15px;
  margin-top: 15px;

 }

 .body-grid-bottom-item{
  width: 100%;
  grid-row-start: 5;
  grid-column-start: 1;
  grid-column-end: 7;
  display: flex;
  flex: 0 0 100%;

 }
 .padding-value{
   padding: 5px;
   text-align: center;
 }

 .card-design {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(238, 231, 231);

}

.datePickerDesign{
  border-radius: 1px;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.rigthCenterContainer{
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 5px;
}

.padding20{
 padding: 20px;
}
.padding10{
  padding: 10px;
 }
.margin10{
  margin: 20px;
}

.margin-top-bottom{
  margin-top: 5px;
  margin-bottom: 5px;
}
.height-400{
  height: 400px;
  
}

.primaryButton{
  border-radius: 3px;
  background-color: rgb(121, 124, 124);
  border: 1px solid rgb(2, 17, 17);
  padding-left:  20px;
  padding-right:  20px;
  padding-top:5px;
  padding-bottom:5px;
  color: white;
  margin: 10px;
 }
 .fullWidth{
   width: 100%;
 }

 .font-big{
   font-size: 1.3rem;
 }

 .font-medium{
  font-size: 1.0rem;
}


 .device-details-container{
   display: grid;
   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr;
   row-gap: 10px;
   column-gap: 10px;
 }

 .device-details-item{

  /* background-color: #345679; */

 }

 .device-details-item-2{
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start:1;
  grid-column-end: 3;

 }

 .background-login{
   background-image: url(../src/assets/images/bg/login-background.png);
   background-size: cover;
 }
 .background-gray{
   background-color: gray;
 }

 .details-item-1{
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr;
   place-items: left;
   
 }



 .display-two-column{
   display: flex;
   justify-content: space-between;
 }

 .display-space-around{
  display: flex;
  justify-content: space-around;
  gap: 2px;
}

 .map-container{
   height: 100vh;
   width: 100%;
 }

 .display-none{
   display: none;
 }

 .light-blue{
   background-color: #5A85FC;
 }

 .green-bg{
  background-color: #2BBE4D;
}

.yellow-bg{
  background-color: #FCB749;
}

.dark-blue-bg{
  background-color: #0FB4AE;
}

.red-bg{
  background-color: #FF3737;
}

.dark-gray-bg{
  background-color: #747172;
}
 
 .dashboard-container{
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
 }
 .dashboard-container-summary{
  display: flex;
  flex-wrap:  wrap;
 }
 .device-main-container{
   display: grid;
   height: 100%;
   grid-template-columns: 1fr;
 }

 .dashboard-item-container{
   width : 200px;
   height: 150px;
   background-color:white;
   border-radius: 10px;
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   display: grid;
   grid-template-rows: 1fr 3fr  1fr;
 }

 .header-row{
  width : 200px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content:center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.dashboar-item-layout{
  display: grid;
  justify-content: center;
  align-items: center;

}

.dashboar-item-center{
  display: grid;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: gray;
  border-radius: 50%;
  color: white;
  font-size:20px;
}

 .device-main-container-avtive-2{
  display: grid;
  grid-template-columns: 1fr minmax(300px, auto);
}

 .height100{
   height: 100px;
 }


.marquee {
  top: 50px;
  position: relative;
  box-sizing: border-box;
  animation: marquee 20s linear infinite;
  margin: 0 auto;
  text-align: center;
  color: #000;
}

@keyframes marquee {
from {
  transform: translateY(0);
}
to {
  transform: translateY(-150%);
}
}

.padding0{
  padding: 0px;
}

.margin0{
  margin: 0px;
}

.font-size-14px{
  font-size: 14px;
}
.font-size-24px{
  font-size: 24px;
}

.marginright10{
  margin-right: 10px;
}


 .list-container{
   display: grid;
   gap: 10px;
   grid-template-rows: auto;
   grid-template-columns: repeat(3, 1fr);
 }



 .list-container-item{
   display: grid;
   grid-template-columns: auto 1fr 30px;
   gap: 2px;

 }

 .center-message-box{
   font-size: 18px;
   color: #333333;
   text-align: center;
 }

 .log-item-container{
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  gap: 2px;

}

.service-hour-item-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;

}
.time-picker-container{
  display: flex;
  gap: 2px;
  align-items: center;
}
.black-font-color{
  color: black;
}

.black-background-color{
  background-color: black;
}
.bold-font{
  font-style: bold;
}

.header-profile-container{
  border: solid 1px #808080;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-size-30{
  width: 30px;
  height: 30px;
}
.grid-style-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-style-container2{
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.grid-style-container3{
  display: grid;
  grid-template-rows:  auto auto  1fr;
  font-size: 10px;
  gap: 4px;
}

.tenant-first-row{
  
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.background-white{
  background-color: white ;
}

.font-size-20px{
  font-size: 20px
}

 .list-left-icon{
   display: flex;
   flex-direction: column;
   justify-items: center;
 }
 .display-center-conatiner{
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
 }

 .grid-4-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: space-between;
  align-items: center;
  gap: 8px;
}

 .card-design-black {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(255, 255, 255);

}

.card-design-header {
  box-shadow: 0 4px 8px 0 rgba(205,205,205,0.2);
  border-radius: 2px;
  font-size: 14px;
  transition: 0.3s;
  background-color: #00000000;
  color: rgb(248, 194, 45);
}

.padding-5px{
  padding: 5px;
}

.middle-container{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  gap: 10px;
}

.tenant-row-actions{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.card-design-item1 {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: rgb(255, 255, 255);
}

.card-design-item2 {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: rgb(235, 235, 235);
}

.popupClass{
  background-color: rgb(255, 255, 255);
}

.card-design-white {
  
  background-color: rgb(255, 255, 255);
}

.indicator-image{
  margin-right: 5px;
  margin-top: 2px;
}
.list-left-icon-layout{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.maquee-items{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}
.marqueeitem{
  padding: 2px; 
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.error-device-dashboard{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 10px;
  flex: 1fr;
}

.disconnected-header{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(255, 0, 0);
  font-size: 1.1rem;
  color: white;
  padding: 10px;
  text-align: center;
}

/* .center-text{
  text-align: center;
} */

.area-design-dotted{
  border-style: dashed;
  border-color: red;
  border-width: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.one-item-per-line{
  display: grid;
  gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
 }

 .card-design-error {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(255, 0, 0);
  display: grid;
  place-items: center;
  color: white;

}
.font-size-medium{
  font-size: 1.2rem;
}

.grid-area-container{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  align-items: space-around;
  gap: 10px;
}

.grid-area-1{
  display: flex;
  width: 100%;
  gap: 10px;
}
.flex-item-1{
  width: 100%;
}
.grid-area-2{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 1px;
}

/* sdfkdsj */


.leaflet-container {
  width: 100%;
  height: 100vh;
}

.container-map{
  width: 100%;
  height: 100vh;
  position: relative;
}
.box{
  width: 100%;
  height: 100%;            
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;  /* for demo purpose  */
}
.stack-top{
  z-index: 9;
  position: absolute;
  top: 100px;
  right: 16px;
  left: 15px;
  font-size: 18px;
}

.topright {
  z-index: 9;
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}

.userview{
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}
.flex-box-container{
  display: flex;
}
.margin-right-10{
  margin-right: 10px;

}

.margin-left-10{
  margin-left: 10px;

}

.align-right-side{
  display: flex;
  align-items: right;             /*center children*/
  justify-content: flex-end;
}

.align-space-between-side{
  display: flex;
  align-items: center;             /*center children*/
  justify-content: space-between;
}

.display-button-two{
  display: grid;
  grid-template-columns: 2fr 3fr;
  justify-content: flex-end;
}



.align-left-side{
  display: flex;
  align-items: left;             /*center children*/
  justify-content: flex-start;
}

.pagination-containter{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: space-between;
}

.tenant-layout-design{
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: left;
}




.paginationButtons{
  width: 80%;
  display: flex;
  list-style: none;
  justify-content: flex-end;
}

.paginationButtons a {
  padding: 5px;
  margin: 5px;
  border: 1px solid gray;
  border-radius: 2px;
  cursor: pointer;
}

.activePaginate a{
  color: white;
  background-color: gray;
}

.paginationButtons a:hover{
  color: white;
  background-color:gray;
}
.centerContent{
  display: flex;
}
.grid2by2{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;


}

.verticalDisplay{
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
  height: 100%;
}

.horizontalDisplay3{
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: center;

}

.width80{
  width: 100px;
}

.flex-middle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.font-color-green{
  color: #00ff00;
}

.font-color-blue{
  color: #0000ff;
}





