.paginationButtons {
  width: 80%;
  display: flex;
  list-style: none;
  justify-content: flex-end;
}
.paginationButtons a {
  padding: 5px;
  margin: 5px;
  border: 1px solid gray;
  border-radius: 2px;
  cursor: pointer;
}
.activePaginate a {
  color: white;
  background-color: gray;
}

.pagination-container {
}
